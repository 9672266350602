@include font-face(MuseoSans, light, /fonts/MuseoSansLight, 300, normal, false );
@include font-face(MuseoSans, regular, /fonts/MuseoSansRegular, 400, normal, false );
@include font-face(MuseoSans, medium, /fonts/MuseoSansMedium, 500, normal, false );
@include font-face(MuseoSans, bold, /fonts/MuseoSansBold, 700, normal, false );
@include font-face(MuseoSans, black, /fonts/MuseoSansBlack, 900, normal, false );

 :root {
	--xs: 0;
	--sm: 576;
	--md: 768;
	--lg: 992;
	--xl: 1200;
	--accent: #ffd63f;
	--red: #f22f46;
	--gray: #333;
	--lightGray: #bbb; }

body {
	background: #FFF;
	font-size: 16px;
	line-height: 1.6;
	overflow-x: hidden;
	font-family: MuseoSans, sans-serif;
	@include smooth-font; }

::-webkit-scrollbar {
	width: 10px; }

::-webkit-scrollbar-thumb {
	background-color: #333;
	cursor: pointer; }

::-webkit-scrollbar-track {
	background: #f6f6f6; }

::selection {
	background: #ffc608;
	color: #fff;
	text-shadow: none; }


.link {
	text-decoration: none; }
