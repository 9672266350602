h1, h2 {
	line-height: 1;
	font-weight: 900;
	margin: 0 0 1em;
	@include css-lock(24, 50, 600, 1000); }


p {
	margin: 1em 0; }

.loader {
	position: fixed;
	background-color: #fff;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	&__text {
		font-size: 22px; } }

.splitter {
	span {
		display: inline-block;
		min-width: 0.3em; } }

.header {
	&__topline {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 25px 55px 0;
		z-index: 40;
		transition: .8s;
		@include r(458) {
			padding: 15px 10px 0; } }

	&__nav {
		ul, li {
			display: inline-block; }
		li {
			margin-left: 30px; }
		a {
			color: #000;
			text-decoration: none; }
		@include r(600) {
			ul {
				display: none; }
			li {
				display: block;
				text-align: center;
				margin: auto; }
			&.mnu_btn__active ul {
				display: block;
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 70px 10px 10px; } } } }


.section {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 468px;
	@include r(600) {
		padding-left: 20px; }
	&__content {
		max-width: 1265px;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 5vh;
		margin-bottom: 5vh;
		position: relative;
		@include r(458) {
			width: 100%;
			padding: 0 10px; } }
	&.alert {
		background: var(--accent);
		&__red {
			background: var(--red);
			color: #fff; } } }


.fs-section {
	height: 100vh; }

.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 55px 40px;
	z-index: 40;
	transition: .8s;
	@include r(458) {
		padding: 0 10px 15px; }
	.link {
		color: #000; }
	&__social {
		position: relative;
		left: 1.4em;
		font-size: 14px;
		letter-spacing: 1px;
		text-transform: uppercase;
		transform: rotate(-90deg);
		transform-origin: 0 100%;
		@include r(600) {
			font-size: 12px;
			padding: 5px 0; }
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 50px;
			height: 1px;
			background: rgba(#000, .25);
			margin-right: 10px;
			right: 100%;
			bottom: 50%; }
		a + a {
			margin-left: 1.4em; } }
	&__creater {
		font-size: 14px; }

	&__mouse {
		position: absolute;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);
		width: 24px;
		height: 40px;
		border-radius: 15px;
		border: 2px solid #000;
		@include r(900) {
			display: none; }
		&_scroll {
			display: block;
			width: 3px;
			height: 3px;
			margin: 5px auto;
			border-radius: 4px;
			background: #000;
			animation: finger 1s infinite; } } }

@keyframes finger {
	0% {
		opacity: 1; }
	100% {
		opacity: 0;
		transform: translateY(20px); } }


.message {
	display: flex;
	max-width: 450px;
	position: fixed;
	bottom: 35px;
	left: 120px;
	padding: 5px 40px 5px 10px;
	border-radius: 5px;
	background: #fff;
	font-size: 14px;
	line-height: 20px;
	opacity: 0;
	filter: drop-shadow(0 7px 10px rgba(#000, .2));
	transform: translateY(50px);
	transition: .5s ease-out;
	z-index: 50;
	@include r(600) {
		left: 40px;
		right: 15px;
		bottom: 15px; }
	&.visible {
		opacity: 1;
		transform: translateY(0); }
	&-icon {
		margin-right: 10px; }
	&-closeBtn {
		position: absolute;
		top: 0;
		right: 0;
		height: 30px;
		width: 30px;
		background: transparent;
		cursor: pointer;
		&:before, &:after {
			content: '';
			position: absolute;
			width: 15px;
			height: 2px;
			background: #000;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg); }
		&:after {
			transform: translate(-50%, -50%) rotate(-45deg); } } }

.success-man {
	position: fixed;
	background: url(/img/success-man.png);
	width: 240px;
	height: 60px;
	bottom: -200px;
	left: -200px;
	transform: rotate(-45deg);
	transform-origin: 0 100%;
	z-index: 55; }

.is {
	display: block;
	background: url('/img/sprite.png') no-repeat;
	&_cookie {
		height: 20px;
		width: 20px;
		background-position: 0 0; } }

