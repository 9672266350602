.code {
	padding: 20px;
	width: 250px; }

.field {
	&__wrap {
		display: inline-block;
		background-color: #f3f3f3;
		cursor: text;
		padding-right: 70px;
		position: relative;
		border-radius: 4px;
		width: 100%;
		max-width: 400px;
		height: 64px;

		.btn__submit {
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			padding: 10px 20px;
			background-color: transparent; } }

	&__label {
		position: absolute;
		top: 25px;
		padding: 0 22px;
		transform: translateY(-5px);
		font-size: 14px;
		pointer-events: none;
		transition: all .2s; }
	&__input {
		// position: absolute
		background-color: transparent;
		width: 100%;
		height: 100%;
		padding: 15px 22px 0;
		font-size: 18px;
		border-bottom: 1px solid transparent;

		&:focus + .field__label, &.field-not-empty + .field__label, &:not(:placeholder-shown) + .field__label {
			transform: translateY(-15px); } } }

.btn__arrow {
	span, span::before, span::after {
		content: '';
		display: block;
		height: 2px;
		background: #000;
		transform-origin: 100% 50%;
		transition: all .5s cubic-bezier(0.5,0,0.3,2); }
	span {
		position: relative;
		width: 35px;
		&:before, &:after {
			position: absolute;
			right: 0;
			top: 0;
			width: 15px;
			transform: rotate(35deg); }
		&:after {
			transform: rotate(-35deg); } }
	&:hover span {
		transform: translateX(-2px);
		&:before {
			transform: rotate(45deg); }
		&:after {
			transform: rotate(-45deg); } }
	&:active {
		span, span::before, span::after {
			transition-duration: .2s; }
		span {
			transform: translateX(5px);
			&:before {
				transform: rotate(30deg); }
			&:after {
				transform: rotate(-30deg); } } }
	&:not(:active) {
		span, span::before, span::after {
			transition-delay: .2s;
			transition-timing-function: ease-out; } }
	&:disabled {
		span, span::before, span::after {
			background: #999; } } }

.mnu_btn {
	position: relative;
	display: block;
	width: 30px;
	height: 30px;
	transition: all .5s cubic-bezier(.5,-0.5,.5,1.5);
	display: none;
	z-index: 500;
	@include r(600) {
		display: block; }
	span, span::before, span::after {
		position: absolute;
		display: block;
		background-color: currentColor;
		width: 30px;
		height: 2px;
		perspective: 100px;
		transition: all .5s cubic-bezier(.5,-0.5,.5,1.5); }

	span {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		&::before, &::after {
			content: ''; }
		&::before {
			top: -8px; }
		&::after {
			top: 8px; } }

	&__active {
		span {
			background-color: transparent; }
		span::before {
			transform: rotate(-45deg) rotateY(-360deg);
			top: 0; }
		span::after {
			transform: rotate(45deg) rotateY(360deg);
			top: 0; } } }


.benefits {
	h2 {
		margin-bottom: 1.5em; }
	&__wrap {
		display: flex;
		flex-flow: row wrap; }

	&__item {
		flex: 1 0 25%;
		font-size: 14 / 16 + rem;
		padding: 10px; } }
